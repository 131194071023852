import { render, staticRenderFns } from "./notice.vue?vue&type=template&id=6107cfd3&scoped=true"
import script from "./notice.vue?vue&type=script&lang=js"
export * from "./notice.vue?vue&type=script&lang=js"
import style0 from "./notice.vue?vue&type=style&index=0&id=6107cfd3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../developTool/Vue/node/node-v16.20.2-win-x64/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6107cfd3",
  null
  
)

export default component.exports